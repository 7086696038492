@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-3xl;
}
h2 {
  @apply text-2xl;
}
h3 {
  @apply text-xl;
}
h4 {
  @apply text-lg;
}
h5 {
  @apply text-base;
}
h6 {
  @apply text-sm;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-medium;
}

/* Antd fixes */
.anticon svg {
  vertical-align: initial;
}
a.primary,
.ant-list-item a,
a.ant-dropdown-trigger {
  color: #1890ff;
}
button.ant-btn-primary {
  background: #1890ff;
}
button.ant-switch {
  background-color: rgba(0, 0, 0, 0.25);
}
button.ant-switch-checked {
  background-color: #1890ff;
}
